<template>
  <div>
    
    <article class="news-item" v-for="item in actus" itemscope="itemscope" itemtype="http://schema.org/Article">
      <picture>
          <source :srcset="item.image" media="(max-width: 992px)">
        <img :src="item.image" alt="" loading="lazy" />
      </picture>
      <a :href="item.url" :title="getLinkTitle(item.title)">
        <h3>
          <span itemprop="headline">{{ item.title }}</span>
        </h3>
      </a>
    </article>
    
  </div>
</template>

<script>
export default {
  name: 'actualities',
  data() {
    return {
      actus: null
    }
  },
  mounted() {
    this.getActus()
  },
  methods: {
    getActus: async function () {
      await this.$http.get('rss/enfanceEtFamille')
      .then(resp => {
        this.actus = resp.data.listeActusRss
      })
    },
    getLinkTitle: function (title) {
      return title+' — Nouvelle fenêtre'
    }
    }
  }
</script>