<template>
  <main class="layout-accueil">
    <header class="layer-primary with-main-form">
      <div class="main-img">
        <img loading="lazy" src="@/assets/assistant-maternel.jpg" width="1800" height="650" alt="" />
        <searchForm homepage></searchForm>
      </div>

      <div class="sidebar-primary txtcenter" style="background-image: url(https://www.maine-et-loire.fr/typo3conf/ext/ressource_maine-et-loire/img/sidebar-primary-bg-icon.svg); background-position: bottom right; background-repeat: no-repeat; background-size: contain">
        <span class="h2-like">&Agrave; consulter également</span>
        <div class="ce-textpic ce-center ce-above">
          <div class="ce-bodytext">
            <ul>
              <li>
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/assistants-maternels" target="_blank" title="Employer un assistant maternel - maine-et-loire.fr (Nouvelle fenêtre)">Employer un assistant maternel</a>
              </li>
              <li>
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/mam" target="_blank" title="Les maisons d'assistants maternels - maine-et-loire.fr (Nouvelle fenêtre)">Les maisons d'assistants maternels</a>
              </li>
              <li>
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde" target="_blank" title="Les différents modes d’accueil - maine-et-loire.fr (Nouvelle fenêtre)">Les différents modes d’accueil</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <section class="section-content" id="section-content">
      <section class="important">
        <img src="https://www.maine-et-loire.fr/fileadmin/Departement/pictos-infographies/picto-attention.svg" alt="" />
        <p>L’actualisation des disponibilités des assistants maternels est saisie en direct par les professionnels eux-mêmes. Le Département de Maine-et-Loire n’intervient pas à ce niveau d’information.</p>
      </section>

      <section class="grid-2-small-1 layer-2col">
        <div>
          <h2>Assistant maternel</h2>
          <ul>
            <li><a href="https://moncompte-assmat.maine-et-loire.fr/" target="_blank" title="Se connecter à mon compte - moncompte-assmat.maine-et-loire.fr (Nouvelle fenêtre)">Se connecter à mon compte</a></li>
            <li><a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel" target="_blank" title="Devenir assistant maternel - maine-et-loire.fr (Nouvelle fenêtre)">Devenir assistant maternel</a></li>
          </ul>
        </div>
        <img loading="lazy" src="https://assmat.maine-et-loire.fr/fileadmin/templates/assmat2/images/actu-assistant-maternel.jpg" alt="" />
      </section>
    </section>

    <div class="layer-news-list">
      <h2>Actualité de l'enfance en Maine-et-Loire</h2>
      <actualities></actualities>
    </div>
  </main>
</template>

<script>
import searchForm from '@/components/searchForm.vue';
import actualities from '@/components/actualities.vue';

export default {
  name: 'Accueil',
  components: {
    searchForm,
    actualities
  }
};
</script>
<style>
.layout-accueil .layer-news-list .video,
.layout-accueil .layer-news-list img {
  height: 25rem;
}

.layer-primary .main-img {
  height: auto;
}

@media (min-width: 992px) {
  .layer-primary .main-img {
    height: 480px;
  }
}
</style>
