<template>
  <div class="autocomplete">
    <label>
      <p v-if="errorCity" @click="closeNoAddressFound">Erreur sur l’adresse</p>
      {{label}}
      <input type="text" @input="onChange($event.target.value)" :value="value" @keydown.down.prevent="onArrowDown" @keydown.up.prevent="onArrowUp" @keydown.enter.prevent="onEnter" @keydown.esc="onEsc" role="combobox" aria-haspopup="listbox" aria-owns="autocomplete-results" :aria-expanded="isOpen ? 'true':'false'" aria-autocomplete="list" aria-controls="autocomplete-results" :aria-activedescendant="activedescendant" required />
    </label>
    <ul id="autocomplete-results" v-show="isOpen" class="input-suggestions" role="listbox" >
      <li class="loading" v-if="isLoading" >
        Loading results...
      </li>
      <li v-else v-for="(result, i) in results" :key="i" @click="setArrowCounter(i) & setResult(result, true)" class="autocomplete-result" :class="{ 'isActive': isSelected(i) }" role="option" :id="getId(i)" :aria-selected="isSelected(i)" >
        {{ result.libelle }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'autocomplete',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true
    },
    search: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
      results: [],
      isLoading: false,
      arrowCounter: -1,
      activedescendant: '',
      errorCity: false
    };
  },
  methods: {
    onChange: function (value) {
      this.$emit('input', value)
      if (value.length > 0) {
        const endpoint = 'adresses?query=';
        this.$http.get(endpoint + value)
        .then((result) => {
          this.isOpen = true;
          this.results = result.data;
          this.errorCity = false 
        })
        .catch((error) => {
          this.results = []
          this.errorCity = true
          this.isOpen = false;
        });
      } else {
        this.results = [];
        this.arrowCounter = -1;
        this.$emit('selected', null);
        this.isOpen = false;
      }
    },
    setResult(result,close=false) {
      this.isOpen = !close;
      this.$emit('selected', result);
    },
    onArrowDown() {
      if (this.isOpen) {
        if (this.arrowCounter < this.results.length-1) {
          this.arrowCounter = this.arrowCounter + 1;
          this.setActiveDescendent();
          this.setResult(this.results[this.arrowCounter])
        }
        else {
          this.arrowCounter = 0;
          this.setActiveDescendent();
          this.setResult(this.results[this.arrowCounter])
        }
      }
    },
    onArrowUp() {
      if (this.isOpen) {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter-1;
          this.setActiveDescendent();
          this.setResult(this.results[this.arrowCounter])
        }
        else {
          this.arrowCounter = this.results.length-1;
          this.setActiveDescendent();
          this.setResult(this.results[this.arrowCounter])
        }
      }
    },
    onEnter() {
      this.setResult(this.results[this.arrowCounter],true)
      this.arrowCounter = -1;
      this.$emit('submit');
    },
    onEsc() {
      this.autoSelect()
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.autoSelect()
      }
    },
    setActiveDescendent() {
      this.activedescendant = this.getId(this.arrowCounter);
    },
    getId(index) {
      return `result-item-${index}`;
    },
    isSelected(i) {
      return i === this.arrowCounter;
    },
    autoSelect() {
      if(this.arrowCounter != -1) {
        this.setResult(this.results[this.arrowCounter],true)
      }
      else if(this.results.length > 0) {
        this.setResult(this.results[0],true)
      }
    },
    closeNoAddressFound() {
      this.autoSelect()
      this.errorCity = false
    },
    setArrowCounter(val) {
      this.arrowCounter = val
    }
  },
  watch: {
    items: function (val, oldValue) {
      // actually compare them
      if (val.length !== oldValue.length) {
        this.results = val;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  }
};
</script>

<style scoped>
.isActive, .autocomplete-result:hover {
  background: var(--color-ecosystem-neutral,#e9e9e9);
}
</style>