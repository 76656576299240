<template>
  <div role="search" class="main-form">
    <form @submit.prevent="submitForm">
      <h2 v-if="homepage">Parents, trouvez un assistant maternel !</h2>
      <div :class="{ 'grid-2-small-1 grid-with-gutter' : advanced }">
        <div class="input-suggestions-wrapper">
          <addressSearchInput label="Commune ou adresse" v-model="search" @selected="selectPoint" @submit="submitForm"></addressSearchInput>
        </div>
        <div class="grid-2-small-1 grid-with-gutter">
          <label>
            Âge de l’enfant (optionnel)
            <select v-model="trancheAge">
              <option v-for="result in resultsAgeRange" :value="result.id">
                {{ result.libelle }}
              </option>
            </select>
          </label>
          <label>
            À partir du (optionnel)
            <input type="date" v-model="dateDebut" :min="getTodayDate()" />
          </label>
        </div>
      </div>
      
      <div v-if="advanced">
        <div class="grid-2-small-1 grid-with-gutter">
          <label>
            Nom et/ou prénom de l’assistant maternel :
            <input type="text" v-model="nomPrenomAssmat" />
          </label>
        </div>
        
        <div class="pills-group" data-toggle="buttons">
          Exerce :
          <label for="exerceToutesStructures" class="pill" :class="{ active: exerce == 0 }">
            Toutes structures
            <input id="exerceToutesStructures" type="radio" value="0" v-model="exerce" />
          </label>
          <label for="exerceDomicile" class="pill" :class="{ active: exerce == 2 }">
            À domicile
            <input id="exerceDomicile" type="radio" value="2" v-model="exerce" />
          </label>
          <label for="exerceMam" class="pill" :class="{ active: exerce == 1 }">
            En Maison d’Assistant Maternel
            <input id="exerceMam" type="radio" value="1" v-model="exerce" />
          </label>
        </div>
        <div class="pills-group" data-toggle="buttons">
          Accepte :
          
          <label for="accepteHorairesAtypiques" class="pill" :class="{ active: (accepteHorairesAtypiques==true || accepteHorairesAtypiques === 'true') }">
            Accueil atypique
            <input id="accepteHorairesAtypiques" type="checkbox" v-model="accepteHorairesAtypiques" />
          </label>
          
          <label for="accepteFaireRemplacements" class="pill" :class="{ active: (accepteFaireRemplacements==true || accepteFaireRemplacements === 'true') }">
            Remplacement
            <input id="accepteFaireRemplacements" type="checkbox" v-model="accepteFaireRemplacements" />
          </label>
          
          <label for="accepteTempsPartiel" class="pill" :class="{ active: (accepteTempsPartiel==true || accepteTempsPartiel === 'true') }">
            Temps partiels
            <input id="accepteTempsPartiel" type="checkbox" v-model="accepteTempsPartiel" />
          </label>
          
          <label for="accepteEnfantsHandicapes" class="pill" :class="{ active: (accepteEnfantsHandicapes==true || accepteEnfantsHandicapes === 'true') }">
            Situation de handicap
            <input id="accepteEnfantsHandicapes" type="checkbox" v-model="accepteEnfantsHandicapes"/>
          </label>
        </div>
        
        <div class="pills-group" data-toggle="buttons">
          Disponibilités :
          <label class="pill" :class="{ active: dispos == 0 }">
            Toutes
            <input type="radio" v-model="dispos" value="0" />
          </label>
          <label class="pill" :class="{ active: dispos == 1 }">
            Actuelles ou futures
            <input type="radio" v-model="dispos" value="1" />
          </label>
          <label class="pill" :class="{ active: dispos == 2 }">
            Non-renseignées
            <input type="radio" v-model="dispos" value="2" />
          </label>
        </div>
      </div>
      
      <input type="submit" value="Lancer la recherche" v-if="homepage" />
      <input type="submit" value="Affiner la recherche" v-if="advanced" />
    </form>
  </div>
</template>

<script>
import addressSearchInput from '@/components/addressSearchInput.vue';

export default {
  name: 'searchForm',
  components: {
    addressSearchInput
  },
  props: {
    advanced: Boolean,
    homepage: Boolean,
    nombreResultatsParPage: 24
  },
  data() {
    return {
      search: null,
      trancheAge: 0,
      dateDebut: null,
      latitude: null,
      longitude: null,
      exerce: 0,
      accepteHorairesAtypiques: false,
      accepteFaireRemplacements: false,
      accepteTempsPartiel: false,
      accepteEnfantsHandicapes: false,
      nomPrenomAssmat: null,
      dispos: 0,
      numPage: 1,
      resultsAddress: [],
      resultsAgeRange: [],
      errored: false,
      errorCity: false,
      suggestions: false
    };
  },
  mounted() {
    this.search = this.$route.query.search;
    this.trancheAge = this.$route.query.trancheAge;
    this.dateDebut = this.$route.query.dateDebut;
    this.latitude = this.$route.query.latitude;
    this.longitude = this.$route.query.longitude;
    this.exerce = this.$route.query.exerce || 0;
    this.accepteHorairesAtypiques = this.$route.query.accepteHorairesAtypiques;
    this.accepteFaireRemplacements = this.$route.query.accepteFaireRemplacements;
    this.accepteTempsPartiel = this.$route.query.accepteTempsPartiel;
    this.accepteEnfantsHandicapes = this.$route.query.accepteEnfantsHandicapes;
    this.nomPrenomAssmat = this.$route.query.nomPrenomAssmat;
    this.dispos = this.$route.query.dispos || 0;
    
    this.$http
    .get('tranchesAge')
    .then((result) => {
      this.resultsAgeRange = result.data;
      if (typeof this.trancheAge == 'undefined') this.trancheAge = '0';
    })
    .catch((error) => {
      console.log(error);
      this.errored = true;
    });
  },
  methods: {
    selectPoint: function (point) {
      if(typeof point == "undefined" || point == null) {
        this.search = '';
        this.latitude = null;
        this.longitude = null;
      }
      else {
        this.search = point.libelle;
        this.latitude = point.latitude;
        this.longitude = point.longitude;
      }
    },
    submitForm: function () {
      this.$router
      .replace({
        name: 'recherche',
        query: {
          search: this.search,
          trancheAge: this.trancheAge,
          dateDebut: this.dateDebut,
          latitude: this.latitude,
          longitude: this.longitude,
          exerce: this.exerce,
          accepteHorairesAtypiques: this.accepteHorairesAtypiques,
          accepteFaireRemplacements: this.accepteFaireRemplacements,
          accepteTempsPartiel: this.accepteTempsPartiel,
          accepteEnfantsHandicapes: this.accepteEnfantsHandicapes,
          nomPrenomAssmat: this.nomPrenomAssmat,
          nombreResultatsParPage: 24,
          dispos: this.dispos
        }
      })
      .catch(() => {});
      this.$emit('research');
    },
    sortByProperty: function (property) {
      return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;
        
        return 0;
      };
    },
    getTodayDate: function () {
      return new Date().toISOString().slice(0, 10);
    }
  }
};
</script>